import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavigationProvider } from './NavigationContext'; // Make sure to create this file
import Home from './components/Home/Home';
import Medic from './components/Medic/Medic';
import Contact from './components/Contact/Contact';
import Error from "./components/404/Error";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CircleLoader from "react-spinners/CircleLoader";
import SwipeableWrapper from "./SwipeableWrapper";
import { LanguageProvider } from './components/Context/LanguageContext';

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AOS.init();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      <LanguageProvider>
        {loading ? (
          <div className="loader-main">
            <CircleLoader
              loading={loading}
              size={200}
              color="#d1132d"
              aria-label="Loading Spinner"
              data-testid="loader"
              className='spinner'
              speedMultiplier={0.6}
            />
          </div>
        ) : (
          <BrowserRouter>
            <NavigationProvider> {/* Wrap Routes with NavigationProvider */}
              <Routes>
                <Route path="/" element={<SwipeableWrapper><Medic /></SwipeableWrapper>}/>
                <Route path="/istanbul" element={<SwipeableWrapper><Home /></SwipeableWrapper>} />
                <Route path="/contact" element={<SwipeableWrapper><Contact /></SwipeableWrapper>} />
                <Route path="*" element={<Error />} />
              </Routes>
            </NavigationProvider>
          </BrowserRouter>
        )}
      </LanguageProvider>
    </>
  );
}

export default App;
