import React, { useState } from 'react';
import './dropdownText.css';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useLanguage } from '../Context/LanguageContext';

const DropdownText = ({ datas }) => {
  const { language } = useLanguage();
  const [showContent, setShowContent] = useState(datas.map(() => false));

  const toggleContent = (index) => {
    console.log(showContent)
    const updatedShowContent = [...showContent];
    updatedShowContent[index] = !updatedShowContent[index];
    setShowContent(updatedShowContent);
  };

  return (
    <>
      <div className="dropdown-container" >
        {datas.map(({ id, title, titleEn, elems, versionEng, isDifferent }, index) => (
          <article
            className={`container_dropdown ${showContent[index] ? 'open' : ''}`}
            key={id} onClick={() => toggleContent(index)}
          >
            <div className='container_title_arrow'>
              <h1 className='dropdown_title'>{language === 'Fr' ? title : titleEn}</h1>
              <span className='container_arrow'>
                {showContent[index] ? (
                  <BsChevronDown />
                ) : (
                  <BsChevronUp />
                )}
              </span>
            </div>

            {/* Conditionally render the dropdown content */}
            {showContent[index] && (
              <ul className='dropdown_content'>
                {elems.map((item, itemIndex) => (
                  <li key={item} className={isDifferent ? 'dropdown_element_different' : 'dropdown_element'}>
                    {isDifferent ? (
                      <div>
                        {item}
                      </div>
                    ) : (
                      <div>
                        {item}
                        <div className='version-fr'>
                          {versionEng[itemIndex]}
                        </div>
                      </div>
                    )}
                    {isDifferent && (
                      <div className='version-diff'>
                        {versionEng[itemIndex]}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}

          </article>
        ))}
      </div>
    </>
  );
};

export default DropdownText;
