import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import "./nav.css"
import "./Dropdown/DropDown.css"
import { HashLink } from 'react-router-hash-link';

function Nav() {
    const [dropdown, setDropdown] = useState(false);
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeBoth = () => {
        if (click === false) {
            setDropdown(true);
            setShowlinks(true);
            setClick(false);
        }
        else {
            setDropdown(false);
            setShowlinks(false);
            setClick(true);
        }
    }
    const onMouseEnter = () => {
        if (window.innerWidth < 940) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };
    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const [showLinks, setShowlinks] = useState(false)

    const handleShowLinks = () => {
        setShowlinks(!showLinks)
    }

    return (
        <>
            <nav>
                <div className='navbar_link'>
                    <a href="/#up" onClick={handleShowLinks} className="linkStyle_logo_container">
                        <img src="icon_ibc.png" className="linkStyle_logo" alt="" />
                    </a>
                </div>
                <ul className='containerNav'>
                    <li className='navbar_link'>
                        <Link to="/#up" className={`linkStyle ${window.location.pathname === '/' ? 'active' : ''}`} onClick={handleShowLinks}>Médical</Link>
                    </li>
                    <li className='navbar_link'>
                        <Link to="/istanbul#up" className={`linkStyle ${window.location.pathname === '/istanbul' ? 'active' : ''}`} onClick={handleShowLinks}>Istanbul</Link>
                    </li>
                    <li className='navbar_link'>
                        <Link to="/contact" className={`linkStyle ${window.location.pathname === '/contact' ? 'active' : ''}`} onClick={handleShowLinks}>Contact</Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Nav
