import {BsFillHandbagFill} from "react-icons/bs"
import {BiWorld} from 'react-icons/bi'
import {GiKnifeFork, GiModernCity} from 'react-icons/gi'
import {IoIosBrush} from 'react-icons/io'
import {MdOutlineExplore} from 'react-icons/md'

export const vid1 = [
    {
        index: 1,
        video: "/assets/videos/Istanbul/world_capital.mp4",
        content: "ISTANBUL, it stands always... The World's capital",
        frenchContent: "ISTANBUL reste toujours... la capitale du monde",
        classContent:"title-vid",
        classVid: "vid vid1",
        alt: "Istanbul, world Capital",
        poster: "/assets",
        icon : BiWorld,
    },
]
export const vid2 = [
    {
        index: 2,
        video: "/assets/videos/Istanbul/experience.mp4",
        content: "Istanbul Experience is the New Cool",
        frenchContent: "L'expérience d'Istanbul est le nouveau cool",
        classContent:"title-vid",
        classVid: "vid vid2",
        alt: "Istanbul Experience",
        poster: "/assets",
        icon : MdOutlineExplore,
    },
]
export const vid3 = [
    {
        index: 3,
        video: "/assets/videos/Istanbul/cool.mp4",
        content: "Istanbul is the New Cool",
        frenchContent: "Istanbul est le nouveau cool",
        classContent:"title-vid",
        classVid: "vid vid3",
        alt: "Istanbul is the new cool",
        poster: "/assets",
        icon : GiModernCity,
    },
]
export const vid4 = [
    {
        index: 4,
        video: "/assets/videos/Istanbul/taste.mp4",
        content: "Taste in Istanbul is the New Cool",
        frenchContent: "Le bon goût d'Istanbul est le nouveau cool",
        classContent:"title-vid",
        classVid: "vid vid4",
        alt: "Taste in Istanbul",
        poster: "/assets",
        icon : GiKnifeFork,
    },
]
export const vid5 = [
    {
        index: 5,
        video: "/assets/videos/Istanbul/shopping.mp4",
        content: "Shopping in Istanbul is the New Cool",
        frenchContent: "Faire du shopping à Istanbul est le nouveau cool",
        classContent:"title-vid",
        classVid: "vid vid5",
        alt: "Shopping in Istanbul",
        poster: "/assets",
        icon : BsFillHandbagFill,
    },
]
export const vid6 = [
    {
        index: 7,
        video: "/assets/videos/Istanbul/art.mp4",
        content: "Art in Istanbul is the New Cool",
        frenchContent: "L'art à Istanbul est le nouveau cool",
        classContent:"title-vid",
        classVid: "vid vid6",
        alt: "Art in Istanbul",
        poster: "/assets",
        icon : IoIosBrush,
    },
]
export const vid7 = [
    {
        index: 8,
        video: "/assets/videos/Istanbul/banner.mp4",
        content: "",
        classContent:"title-vid",
        classVid: "vid_banner vid6",
        alt: "banner video",
        poster: "/assets",
        icon : IoIosBrush,
    },
]
export const departures = [
    {
        title: "Arrivées",
        image: "assets/images/carousel/arrivee2.jpg"
    }, 
    {
        title: "Départs",
        image: "assets/images/carousel/avion.jpg"
    },
    {
        title: "Départs",
        image: "assets/images/carousel/aeroport_istanbul.jpg"
    },

]
export const transfers = [
    {
        title: "Transferts",
        image: "assets/images/carousel/transfert1.jpg"
    },
    {
        title: "Transferts",
        image: "assets/images/carousel/transfert3.jpg"
    },
]
export const hostels = [
    {
        title: "Hôtels",
        image: "assets/images/carousel/hotel_1.jpg"
    },
    {
        title: "Hôtels",
        image: "assets/images/carousel/hotel_2.jpg"
    },
    {
        title: "Hôtels",
        image: "assets/images/carousel/hotel_3.jpg"
    },
]
export const clinics = [
    {
        title: "Cliniques",
        image: "assets/images/carousel/clinique_1.jpg"
    },
    {
        title: "Cliniques",
        image: "assets/images/carousel/clinic3.jpg"
    },
    {
        title: "Cliniques",
        image: "assets/images/carousel/clinic4.jpg"
    },
    {
        title: "Cliniques",
        image: "assets/images/carousel/clinic5.jpg"
    },
]
export const arrivee = [
    {
        title: "Arrivées",
        image: "assets/images/carousel/arrivee3.jpg"
    },   

    {
        title: "Arrivées",
        image: "assets/images/carousel/arrivee1.jpg"
    },
 
]

export const homeVideos1 = [
    {
        video: "assets/videos/Istanbul/vid-home-1.mp4"
    },   
 
]

export const homeVideos2 = [
    {
        video: "assets/videos/Istanbul/vid-home-3.mp4"
    },   
 
]
export const home1 = [
    {
        title: "PARIS - GENEVE - MILAN",
        titleEn : "PARIS - GENEVA - MILAN",
        image: "assets/images/carousel/avion.jpg",
        subtitle: "S'ENVOLER VERS ISTANBUL",
        subtitleEn : "FLY TO ISTANBUL",
    },
    {
        title: "TRANSFERTS",
        titleEn : "TRANSFERS",
        image: "assets/images/carousel/transfert1.jpg",
        subtitle: "AEROPORT - HOTELS - CENTRES DE SOINS",
        subtitleEn : "AIRPORT - HOTELS - HEALTH CENTERS",

    },
    {
        title: "RESERVATION",
        titleEn : "BOOKING",
        image: "assets/images/carousel/hotel_1.jpg",
        subtitle: "HOTELS - PALACES - APPARTEMENTS PRIVES",
        subtitleEn : "HOTELS - PALACES - PRIVATE APPARTMENTS",
    },
    {
        title: "TOUTES LES ACCREDITATIONS INTERNATIONALES",
        titleEn : 'ALL INTERNATIONAL ACREDITATIONS',
        image: "assets/images/carousel/clinique_1.jpg",
        subtitle: "PARTENAIRE DES UNIVERSITES DE HARVARD & BOSTON MEDICAL",
        subtitleEn : "PARTNER OF HARVARD & BOSTON MEDICAL UNIVERSITIES",
    },
    {
        title: "CONTACT",
        titleEn: "CONTACT",
        image: "assets/images/carousel/arrivee3.jpg",
        subtitle: "RENDEZ-VOUS PRE OPERATOIRE/ SUIVI POST OPERATION",
        subtitleEn : "PRE OPERATIVE RENDEZ-VOUS / POST OPERATIVE FOLLOW - UP",
    },
]
export const home2 = [
    {
        title: "UNE EXPERIENCE UNIQUE",
        titleEn: "A UNIQUE EXPERIENCE",
        image: "/home2/1.jpg",
        subtitle: "CULTURELLE - HISTORIQUE - ARTISTIQUE- CULINAIRE - GASTRONOMIQUE",
        subtitleEn : "CULTURAL - HISTORICAL - ARTISTIC - CULINARY - GASTRONOMICAL",
    },
    {
        title: "CULTURELLE ET HISTORIQUE",
        titleEn: "CULTURAL AND HISTORICAL",
        image: "/home2/2.jpg",
        subtitle: "Découvrez nos offres d'accompagnement personnalisées",
        subtitleEn : "Discover our personalized accompanyment offers",
    },
    {
        title: "ARTISTIQUE",
        titleEn: "ARTISTIC",
        image: "/home2/4.jpg",
        subtitle: "ARCHITECTURE - SCULPTURE - PEINTURE - LITTERATURE",
        subtitleEn : "ARCHITECTURE - SCULPTURE - PAINTING - LITTERATURE",
    },
    {
        title: "CULINAIRE ET GASTRONOMIQUE",
        titleEn: "CULINARY AND GASTRONOMICAL",
        image: "/home2/3.jpg",
        subtitle: "Une cuisine méditerranéenne et ensoleillée à la croisée des continents Européen et Asiatique...",
        subtitleEn : "A mediterranean and sunny cuisine in the crossroads of Europe and Asia...",
    },
    {
        title: "ET ENFIN, PROFITEZ DE VOTRE SHOPPING !",
        titleEn: "AND FINALLY, ENJOY YOUR SHOPPING !",
        image: "/home2/5.jpg",
        subtitle: "",
        subtitleEn : "",
    },
]