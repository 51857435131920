import React from 'react'
import "./carousel.css"
import { useState, useEffect } from "react"
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md"
import CircleLoader from "react-spinners/CircleLoader";

function Carousel({ images }) {
    const [touchPosition, setTouchPosition] = useState(null)
    // ...
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
        e.stopPropagation();
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 5) {
            slideRight()
        }
    
        if (diff < -5) {
            slideLeft()
        }
    
        setTouchPosition(null)
        e.stopPropagation();
    }
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3500)
    }, [])
    //<p className={image.classTitle}>{image.title}</p>
    const [current, setCurrent] = useState(0)
    const [autoPlay, setAutoPlay] = useState(true);
    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1)
    }
    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    }
    let timeOut = null;

    return (
        <div className="App">
            {
                loading ?
                    <div className="loader">
                        <CircleLoader
                            loading={loading}
                            size={150}
                            color="#d1132d"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='spinner'
                            speedMultiplier={0.5}
                        /></div>
                    : <>
                        <div className='animation-apparition'></div>
                        <div className='carousel_container'>
                            <div className='carousel'>
                                <div className='carousel_wrapper'>

                                    {images.map((image, index) => {
                                        return (
                                            <div key={index} className={index === current ? "carousel_card carousel_card-active" : "carousel_card"}>
                                                <img className='card_image' src={image.image} alt="" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}/>
                                                <div className='card-overlay'>
                                                    <MdArrowBackIos className='carousel_arrow_left' onClick={slideLeft} />
                                                    <span className='carousel_arrow_right' onClick={slideRight}><MdArrowForwardIos /></span>
                                                    <div className="carousel_pagination">
                                                        {images.map((_, index) => {
                                                            return (
                                                                <div key={index} className={index == current ? "pagination_dot pagination_dot-active" : "pagination_dot"}
                                                                    onClick={() =>
                                                                        setCurrent(index)

                                                                    }
                                                                ></div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default Carousel