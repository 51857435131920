import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigation } from './NavigationContext';

function SwipeableWrapper({ children }) {
  const location = useLocation();
  const { navigateTo } = useNavigation();
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const navigationMap = {
    '/istanbul': { next: '/contact', prev: '/' },
    '/contact': { next: null, prev: '/istanbul' },
    '/': { next: '/istanbul', prev: null },
    // Ajoutez d'autres chemins si nécessaire
  };

  function handleTouchStart(e) {
    setTouchStartX(e.changedTouches[0].screenX);
    setTouchStartY(e.changedTouches[0].screenY);
  }

  function handleTouchEnd(e) {
    setTouchEndX(e.changedTouches[0].screenX);
    setTouchEndY(e.changedTouches[0].screenY);
    handleSwipeGesture();
  }

  function handleSwipeGesture() {
    const deltaX = touchEndX - touchStartX;
    const deltaY = Math.abs(touchEndY - touchStartY);

    // Vérifiez si le mouvement est principalement horizontal, significatif et si le déplacement vertical est négligeable
    if (Math.abs(deltaX) > 50 && deltaY < 20) {
      const currentPage = navigationMap[location.pathname];

      if (deltaX < 0 && currentPage.next) {
        navigateTo(currentPage.next); // Swipe vers la gauche
      } else if (deltaX > 0 && currentPage.prev) {
        navigateTo(currentPage.prev); // Swipe vers la droite
      }
    }
  }

  return (
    <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      {children}
    </div>
  );
}

export default SwipeableWrapper;
