import React from 'react'
import "./carousel.css"
import { useState, useEffect } from "react"
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md"
import CircleLoader from "react-spinners/CircleLoader";
import { useLanguage } from '../Context/LanguageContext.jsx';

function HeroCarousel({ images , numberCase}) {
    const { language } = useLanguage();

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        },4000)
    }, [])

    const [current, setCurrent] = useState(0)
    const [autoPlay, setAutoPlay] = useState(true);
    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1)
    }
    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    }
    useEffect(() => {
        timeOut = autoPlay && setTimeout(() => {
            slideRight();
        }, 4000)
    })
    let timeOut = null;

    return (
         <>
                        <div className='animation-apparition'></div>
                        <div className='carousel_container margin_carousel'>
                            <div className='carousel'>
                                <div className='carousel_wrapper'>

                                    {images.map((image, index) => {
                                        return (
                                            <div key={index} className={index === current ? "carousel_card2 carousel_card-active2" : "carousel_card2"}>
                                                <img className={`card_image2 ${numberCase === 'medic' ? 'box-shadow' : ''}`} src={image.image} alt="" />
                                                <div className='card-overlay'>
                                                    <div className='carousel_text'>
                                                        <h1 className='carousel_title'>{language === "Fr" ? image.title : image.titleEn}</h1>
                                                        <h2 className='carousel_subtitle'>{language === "Fr" ? image.subtitle : image.subtitleEn}</h2>
                                                    </div>
                                                    <div className="carousel_pagination2">
                                                        {images.map((_, index) => {
                                                            return (
                                                                <div key={index} className={index == current ? "pagination_dot pagination_dot-active" : "pagination_dot"}
                                                                    
                                                                ></div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </>
          
    );
}

export default HeroCarousel