import React, {useEffect, useState} from 'react'
import "./home.css"
import Nav from '../Nav/Nav'
import { vid1, vid2, vid3, vid4, vid5, vid6, vid7, home2 } from "../Data"
import Videos from './Videos'
import { homeVideos1 } from "../Data"
import {BsFillHandbagFill} from "react-icons/bs"
import {BiWorld} from 'react-icons/bi'
import {GiKnifeFork, GiModernCity} from 'react-icons/gi'
import {IoIosBrush} from 'react-icons/io'
import {MdOutlineExplore, MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md'
import { Link } from "react-scroll"
import HeroCarousel from '../Carousels/HeroCarousel'
import VideoBanner from '../VideoBanner/VideoBanner'
import ChangeLanguage from '../Context/ChangeLanguage'
import { useLanguage } from '../Context/LanguageContext.jsx';

function Home() {
    const { language } = useLanguage();
    return (
        <div className="App">
            <div id='up'></div>
            <ChangeLanguage />
            <div style={{minHeight: '100vh'}}>
            <HeroCarousel images={home2}/>
            </div>
            <VideoBanner/>
            <div className='container_arrows'>
            <div className='container_arrow_down' id="container_arrow_down">
                    <Link to='home' spy={true} smooth={true} offset={0} duration={6000} id='arrow_down'><button href="home" id='button_arrow' className='button_arrow'><span><MdKeyboardArrowDown /></span></button></Link>
                    </div>
                    <div className='container_arrow_up' id="container_arrow_down">
                    <Link to='up' spy={true} smooth={true} offset={0} duration={6000} id='arrow_down'><button href="up" id='button_arrow' className='button_arrow'><span><MdKeyboardArrowUp /></span></button></Link>
                    </div>
                    </div>
                        <Nav />
                        
                        <section className='videos_istanbul'>
                        <span className='height'></span>
                        <div data-aos="fade-up" data-aos-duration="1000" className='container_title_istanbul'>
                            <h1 className='istanbul_title'>{language === "Fr" ? "Des offres personnalisées d'accompagnement pour vous guider lors de votre séjour culturel, gastronomique..." : "Personalized Accompanying Offers to Guide You During Your Travel Culture, Culinary..."}</h1>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <span className='container-all-icons_istanbul'>
                            <Link to='world'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}>
                                <BiWorld />
                            </Link>
                            <Link to='experience'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}>
                                <MdOutlineExplore />
                            </Link>
                            <Link to='cool'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}><GiModernCity /></Link>
                            <Link to='taste'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}><GiKnifeFork /></Link>
                            <Link to='shopping'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}><BsFillHandbagFill /></Link>
                            <Link to='art'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}><IoIosBrush/></Link></span>
                        </div>
                        <div id="world"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid1} />
                        </div>
                        <div id="experience"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid2} />
                        </div>
                        <div id="cool"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid3} />
                        </div>
                        <div id="taste"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid4} />
                        </div>
                        <div id="shopping"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid5} />
                        </div>
                        <div id="art"></div>
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <Videos videos={vid6} />
                        </div>
                        </section>
                        <div data-aos="fade-up" data-aos-duration="1000">
                        <article className='announcement2'><p className='contact_us_medic'>{language === "Fr" ? "Pour toute information complémentaire," : "For any additional information,"}</p><a href='/contact' className='btn-contact'>{language === "Fr" ? "Contactez-nous" : "Contact-us"}</a></article>
                    </div>
                        <div id='home'></div>
        </div>
    )
}

export default Home