import React from 'react'
import "./error.css"
import Nav from '../Nav/Nav'
function Error() {
    return (
        <>
            <Nav />
            <section className='container_404'>
                <article className='article_404'>
                    <span className='container_hover'>
                        <p className='textError'>Erreur 404, Mauvais URL.<br />Pour revenir à la page d'accueil,</p>
                        <a href="/">
                            Cliquer ici
                        </a>
                    </span>
                </article>
            </section>
        </>
    )
}

export default Error