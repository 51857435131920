import React from 'react'
import "./contact.css"
import Nav from '../Nav/Nav'
import { useRef } from 'react'
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLanguage } from '../Context/LanguageContext.jsx';
import ChangeLanguage from '../Context/ChangeLanguage.jsx'
import Footer from '../Footer/Footer.jsx'

function Contact() {
    const { language } = useLanguage();
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE, process.env.REACT_APP_TEMPLATE, form.current, process.env.REACT_APP_THIRD)

        e.target.reset();
        toast.success('Message envoyé !', {
            className: "toast-message"
        })

    };
    return (
        <>
            <ToastContainer />
            <ChangeLanguage />
            <Nav />
            <section className='container_contact' id='contact'>
                <p className='contact_us'>{language === "Fr" ? "Pour toute information concernant nos prestations personnalisées," : "For any additional information about our personalized services,"}</p><p className='contact_us_second'>{language === "Fr" ? "Contactez-nous" : "Contact-us"}</p>
                <form ref={form} onSubmit={sendEmail} className='container_inputs'>
                    <div>
                        <input id='name' type="text" name='name' placeholder={language === "Fr" ? "Nom / Prénom": "Name / First Name"} required />
                        <label htmlFor="name">{language === "Fr" ? "Nom / Prénom": "Name / First Name"}</label>
                    </div>
                    <div>
                        <input id='email' type="email" name='email' placeholder='Email' required />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div>
                        <input id='tel' type="tel" name='tel' placeholder={language === "Fr" ? "N° Tel": "Phone Number"} required />
                        <label htmlFor="tel">{language === "Fr" ? "N° Tel": "Phone Number"}</label>
                    </div>
                    <div>
                        <textarea id='textarea' name='message' placeholder='Message' rows="10" spellCheck="true" required></textarea>
                        <label htmlFor="textarea">Message</label>
                    </div>
                    <div className='container_btn_submit'>
                        <button type="submit" id="submit" className='btn-submit'>{language === "Fr" ? "Envoyer" : "Send"}</button>
                        <label htmlFor="submit">{language === "Fr" ? "Envoyer" : "Send"}</label>
                    </div>

                </form>
            </section>
        </>
    )
}

export default Contact