import React from 'react';
import './videoBanner.css';
import Video from '../assets/banner.mp4'

const VideoBanner = () => {
  return (
    <div className="video-banner">
      <video className='video' alt='Istanbul, Meeting Point of Civilizations' autoPlay loop muted width="100%" height="auto" type="video/mp4" controlsList="nodownload">
        <source src={Video} type="video/mp4" />
        Votre navigateur ne supporte pas les vidéos HTML5.
      </video>
    </div>
  );
}

export default VideoBanner;
