import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./medic.css"
import Nav from '../Nav/Nav'
import Caroussel from '../Carousels/Carousel'
import { departures, transfers, hostels, clinics, arrivee, home1 } from '../Data'
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa"
import { RiCarFill } from "react-icons/ri"
import { SiHotelsdotcom } from "react-icons/si"
import { MdLocalHospital } from "react-icons/md"
import { homeVideos2 } from "../Data"
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md'
import { Link } from "react-scroll"
import HeroCarousel from '../Carousels/HeroCarousel'
import DropdownText from '../DropdownText/DropdownText'
import ChangeLanguage from '../Context/ChangeLanguage';
import { useLanguage } from '../Context/LanguageContext.jsx';

function Medic() {
  const { language } = useLanguage();

        const locationData = {
            firstList: [
              'Blepharoplasty',
              'Botox Injection - Hyaluronic Acid',
              'Bichectomy',
              'Brow Lift',
              'Cheek Implants',
              'Cleft Lip lateral - bilateral',
              'Dermabrasion',
              'Dimple Creation',
              'Facelift',
              'Facial fat transfer',
              'Lip surgery',
              'Menthoplasthy',
              'Neck Lifting - Liposuction',
              'Otoplasty', 
              'Rhinoplasty', 
              'Rhinomenthoplasty - Noze and Chin', 
              'Scar Revision',
              'Hair Transplantation - Stem Cells',
              'Beard Transplantation'
            ],
            firstListEng: [
              'Blépharoplastie - Chirurgie des paupières',
              'Toxine Botulique - Acide Hyaluronique',
              'Bichectomie - Chirurgie des joues',
              'Lifting des sourcils',
              'Implants de joue et de pomettes',
              'Fente labiale',
              'Cicatrices du visage - Dermabrasion',
              'Création de faussettes',
              'Lifting facial',
              'Transfert de graisse visage',
              'Chirugie des lèvres',
              'Menthoplasthie - Chirurgie du menton',
              'Lifting du coup - Liposuccion',
              'Otoplastie - Chirurgie des oreilles',
              'Rhinoplastie - Chirugie du nez',
              'Profiloplastie - Chirurgie du nez et du menton',
              'Soins cutanés',
              'Implants capillaires - Technologies Stem Cells',
              'Implants de Barbe'
            ],
            secondList: [
                'Brachiaplasty',
                'Calf Implants - Reduction',
                'Liposuction Vaser',
                'Liposuction Laser',
                'Thigh Lift',
              ],
              secondListEng: [
                'Brachiaplastie - Lifting des bras',
                'Implants - Réduction des mollets',
                'Liposuccion à vibrations sonores',
                'Liposuccion à canules chauffantes',
                'Chirurgie des cuisses',
              ],
              thirdList: [
                'Abdominoplasty',
                'Mastopexy - Reconstruction',
                'Breast Lifting - Liposuction',
                'Breast Reduction',
                'Buttock Lifting - Lipoliting ( NOT IMPLANTS )',
                'Dorsoplasty',
                'Gastric Bypass',
                'Gastrectomy Sleeve',
                'Lipofilling',
                'Mommy Makeover',
                'Nipple Augmentation'
              ],
              thirdListEng: [
                "Abdominoplastie - Chirurgie de l'abdomen",
                'Mastopexie - Augmentation - Reconstruction mammaire',
                'Lipoliftings mammaires',
                'Réduction mammaire',
                "Lifting des fesses ( PAS D'IMPLANTS )",
                'Dorsoplastie',
                'By-pass Gastrique',
                'Sleeve Gastrectomie',
                'Liposculpture et autogreffe de tissus graisseux',
                'Chirurgie de la restauration du corps',
                'Augmentation chirurgicale des mamelons'
              ],
              fourthList: [
                'Gynecomastia',
                'Hymenoplasty',
                'Labioplasty',
                'Penoplasty',
                'Vaginoplasty',
              ],
              fourthListEng: [
                "Gynecomastie - Chirurgie de l'intime masculin",
                "Hymenoplasty - Chirurgie de l'hymen",
                "Labioplastie - Chirurgie de l'intime féminin",
                "Penoplastie - Chirurgie de l'intime masculin",
                "Vaginoplastie - Chirurgie de l'intime féminin",
              ],
              fifthList: [
                'Gamma Knife Radiochirurgie intra-crânienne',
                "Embryoscopie / Cryo-conservation d'embryons",
                'MRIdian Radiothérapie guidé par IRM',
                "Centre de médecine du sport agréé par la FIFA",
                "Orthopédie / Traumatologie",
                "Cardiologie pédiatrique à coeur ouvert et mini-invasive",
                
              ],
              fifthListEng: [
                
                'CyberKnife Radiochirurgie robotisé',
                'FIV / ICSI / IMSI',
                
              ],
              sixList: [
                'Dental tomography',
                'Panoramic x-ray ',
                'Dental Examination',
                'Dental X-ray Film',
                'Lokal Anestezi',
                'Extirpasyon (Dentatreanation)',
                'Mock Up ',
                'Oral and Maxillofacial Surgery Implant (ASTRA EV)',
                'Abutment for Astra EV',
                'Implant (MIS-XIVE)',
                'Implant (Straumann)',
                'Abutment for Straumann',
                'All-on-Four (Megagen) with Sedation',
                'All-on-Six (Megagen) with Sedation ',
                'Autogenous bone graft',
                'Sinus lift operation (open - excluding biomaterial fee)',
                'Sinus lift operation (Closed - excluding biomaterial fee)',
                'Apical Resection',
                'Cyst enucleation',
                'Tooth Extraction',
                'Comlicated Tooth Extraction',
                'Impacted tooth extraction',
                'Impacted tooth extraction (with bone retention)',
                'Periodontology Free gingival graft',
                'Flap operation- half jaw',
                'Free connective tissue graft (single tooth)',
                'Gingivectomy (single tooth)',
                'Subgingival Curettage (half jaw)',
                'Tooth  Cleaning',
                'Gingivoplasty (single tooth)',
                'Crown extension',
                'Prosthesis Bleaching with Laser  (double jaw)',
                'Botox TME dysfunction practice(Superficial for each muscle)',
                'Gnathology, TMJ muscle examination with occlusual splint Porcelain Laminate ( Ceramic veneer )',
                'CEREC Inlay - Onlay',
                'CEREC Crown',
                'Zirconium Crown',
                'Metal Porcelain Crown',
                'Temporary Crown',
                'Immediate Temporary Prosthesis',
                'Partial Denture (metal-single jaw)',
                'Crown disassembly (for single fixed member)',
                'Total Denture (metal-single jaw)',
                'Precision Attachment (each)',
                'Resin Cementation',
                'Endodontics Fiber Post Restoration of Root c-Canal',
                'Root canal treatment - one canal (excluding filling)',
                'Root canal treatment - two canal (excluding filling)',
                'Root canal treatment - three canal (excluding filling)',
                'Root canal therapy of periapical lesion (one canal, excluding filling)',
                'Root canal therapy of periapical lesion (two canals, excluding filling)',
                'Root canal therapy of periapical lesion (three canals, excluding filling)',
              ],
              sixthListEng: [
                'Tomographie dentaire 3D',
                'Radiographie panoramique',
                'Examen dentaire',
                'Film radiographique dentaire',
                'Anesthèsie locale',
                'Extirpasyon (Traitement dentaire)',
                'Maquette',
                'Implant de chirurgie buccale et maxillo-faciale (ASTRA EV)',
                'Pilier pour Astra EV',
                'Implant (MIS-XIVE)',
                'Implant (Straumann)',
                'Pilier pour Straumann ',
                'All-on-Four (Megagen) avec sédation',
                'All-on-Six (Megagen ) avec sédation',
                'Greffe osseuse autogène',
                'Opération de lifting des sinus (ouverte - hors frais de biomatériau)',
                'Opération de lifting des sinus (fermée - hors frais de biomatériau)',
                'Apical Resection',
                'Énucléation des kystes',
                'Extraction de dent',
                'Dent compliquée Extraction',
                'Extraction de dent incluse',
                'Extraction de dent incluse (avec rétention osseuse)',
                'Parodontologie Greffe gingivale gratuite',
                'Opération du lambeau - demi-mâchoire',
                'Greffe de tissu conjonctif gratuite (dent unique)',
                'Gingivectomie (dent unique)',
                'Curetage sous-gingival (demi-mâchoire)',
                'Nettoyage dentaire',
                'Gingivoplastie (dent unique)',
                'Extension de couronne',
                'Blanchiment de prothèse au Laser (double mâchoire)',
                'Pratique du dysfonctionnement du Botox TME (Superficiel pour chaque muscle)',
                'Gnathologie, examen musculaire ATM avec occlusion attelle Stratifié en porcelaine (facette en céramique)',
                'Inlay - Onlay CEREC',
                'Couronne CEREC',
                'Couronne en zirconium',
                'Couronne en porcelaine métallique',
                'Couronne provisoire',
                'Prothèse provisoire immédiate',
                '',
                'Démontage de la couronne (pour un seul membre fixe) ',
                'Prothèse totale (métal-mâchoire unique',
                'Prothèse totale (acrylique - simple mâchoire)',
                'Attachement de précision (chacune)',
                'Cimentation en résine',
                'Endodontie Post-restauration fibreuse du canal c radiculaire',
                'Endodontie Post-restauration fibreuse du canal c radiculaire',
                'Traitement canalaire - un canal (hors obturation)',
                'Traitement canalaire - deux canaux (hors obturation)',
                'Traitement canalaire - trois canaux (hors obturation)',
                "Térapie canalaire d'une lésion périapicale (un canal, à l'exclusion du remplissage) T",
                "Thérapie canalaire d'une lésion périapicale (deux canaux, à l'exclusion du remplissage) ",
                "Thérapie canalaire d'une lésion périapicale (trois canaux, à l'exclusion du remplissage",
              ],
          };
          
          const navigate = useNavigate();
          let touchStartX = 0;
          let touchEndX = 0;

          const handleTouchStart = (e) => {
            touchStartX = e.touches[0].clientX;
          };
        
          const handleTouchEnd = (e) => {
            touchEndX = e.changedTouches[0].clientX;
            handleSwipeGesture();
          };
        
          const handleSwipeGesture = () => {
            const swipeLength = touchStartX - touchEndX;
        
            if (swipeLength > 50) {
              navigate('/istanbul');
            } else if (swipeLength < -50) {
              
            }
          };

    return (
                    <>
            <div id='up'></div>
            <ChangeLanguage />
            <div style={{minHeight: '100vh'}} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            <HeroCarousel images={home1} numberCase="medic"/>
            </div>
            <div className='container_arrows'>
            <div className='container_arrow_down' id="container_arrow_down">
                    <Link to='down' spy={true} smooth={true} offset={0} duration={6000} id='arrow_down'><button href="down" id='button_arrow' className='button_arrow'><span><MdKeyboardArrowDown /></span></button></Link>
                    </div>
                    <div className='container_arrow_up' id="container_arrow_down">
                    <Link to='up' spy={true} smooth={true} offset={0} duration={6000} id='arrow_up'><button href="up" id='button_arrow' className='button_arrow'><span><MdKeyboardArrowUp /></span></button></Link>
                    </div>
                    </div>
            <Nav />
            <section className='section_logistique'>
            
            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                <article className='container-title-accompagnements'>
                <p className='title_medic_general underline_main'><Link to='accompagnements' className='link_title' spy={true} smooth={true} offset={-100} duration={1000}>{language === "Fr" ? "Accompagnements" : "Accompagnyments"}</Link> {language === "Fr" ? "et" : "and"} <Link to='prestations' className='link_title' spy={true} smooth={true} offset={0} duration={1000}>Prestations</Link></p>
                   
                    <section id="accompagnements"></section>
                    <p className='title_logistique underline'>{language === "Fr" ? "Accompagnements" : "Accompagnyments"}</p>
                    <p className='main-title_prestations'>
                      {language === "Fr" ? 
                        <>
                          Un Full Package incluant, du décollage (aller), à l'atterissage (retour), les transferts, la clinique, l'hébergement, les visites touristiques...
                          <br />
                          Et le guide touristique-accompagnateur, français(e) !
                        </>
                        :
                        <>
                          A full package including, the boarding (onward), to the departure (return), transfers, the clinic, the hotel, touristic visits...
                          <br />
                          And the touristic guide, master !
                        </>
                      }
                    </p>
                    <div data-aos="fade-up" data-aos-duration="1000">
                    <span className='container-all-icons'>
                    <Link to='departs'className='className="linkStyle"'spy={true} smooth={true} offset={-100} duration={1000}>
                        <FaPlaneDeparture />
                    </Link>
                    <Link to='transfers'className='className="linkStyle"'spy={true} smooth={true} offset={-200} duration={1000}>
                        <RiCarFill />
                        </Link>
                        <Link to='hostels'className='className="linkStyle"'spy={true} smooth={true} offset={-200} duration={1000}><SiHotelsdotcom /></Link>
                        <Link to='health'className='className="linkStyle"'spy={true} smooth={true} offset={-200} duration={1000}><MdLocalHospital /></Link><Link to='arrivals'className='className="linkStyle"'spy={true} smooth={true} offset={-300} duration={1000}><FaPlaneArrival /></Link></span>
                    </div>
                </article>
            </div>
                <div data-aos="fade-up" data-aos-duration="1000">
                <article className='container-carousel-margin' id='departs'>
                    <span className='container-titles-carousel'>
                        <span className='icon-carousel'><FaPlaneDeparture /></span>
                        <p className='title_carousel'>{language === "Fr" ? "Départs" : "Departures"}</p>
                        <span className='icon-carousel'><FaPlaneDeparture /></span>
                    </span>
                    <span className='container-titles-carousel'><p className='sub-title_carousel'>{language === "Fr" ? "Décollage" : "Take off"}</p></span>
                </article>
                <Caroussel images={departures} />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" id='transfers'>
                <article className='container-carousel'>
                    <span className='container-titles-carousel'>
                        <span className='icon-carousel'><RiCarFill /></span>
                        <p className='title_carousel_seconds'>{language === "Fr" ? "Transferts" : "Transfers"}</p>
                        <span className='icon-carousel'><RiCarFill /></span>
                    </span>
                    <span className='container-titles-carousel2'><p className='sub-title_carousel'>{language === "Fr" ? "Transferts & Chauffeurs VIP" : "VIP Transfers & Drivers"}</p></span>
                </article>
                <Caroussel images={transfers} />
                </div>
                
                <div data-aos="fade-up" data-aos-duration="1000" id='hostels'>
                <article className='container-carousel'>
                    <span className='container-titles-carousel'>
                        <span className='icon-carousel'><SiHotelsdotcom /></span>
                        <p className='title_carousel_seconds'>{language === "Fr" ? "Hôtels" : "Hotels"}</p>
                        <span className='icon-carousel'><SiHotelsdotcom /></span>
                    </span>
                    <span className='container-titles-carousel2'><p className='sub-title_carousel'>{language === "Fr" ? "Chambre d'Hôtel &  Appartements privés" : "Hospital Room & Private Apartments"}</p></span>
                </article>
                <Caroussel images={hostels} />
                </div>

                <div data-aos="fade-up" data-aos-duration="1000" id='health'>
                <article className='container-carousel'>
                    <span className='container-titles-carousel'>
                        <span className='icon-carousel'><MdLocalHospital /></span>
                        <p className='title_carousel_seconds'>{language === "Fr" ? "Cliniques" : "Health Centers"}</p>
                        <span className='icon-carousel'><MdLocalHospital /></span>
                    </span>
                    <span className='container-titles-carousel2'><p className='sub-title_carousel'>{language === "Fr" ? "Centres et Cliniques Médicaux" : "Medical & Clinical Centers"}</p></span>
                </article>
                <Caroussel images={clinics} />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" id='arrivals'>
                <article className='container-carousel'>
                    <span className='container-titles-carousel'>
                        <span className='icon-carousel'><FaPlaneArrival /></span>
                        <p className='title_carousel_seconds'>{language === "Fr" ? "Arrivées" : "Arrivals"}</p>
                        <span className='icon-carousel'><FaPlaneArrival /></span>
                    </span>
                    <span className='container-titles-carousel2'><p className='sub-title_carousel'>{language === "Fr" ? "Atterissage" : "Landing"}</p></span>
                </article>
                <Caroussel images={arrivee} />
                </div>
            </section>
        <div id='prestations'></div>
        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            <section className='section_prestation'>
            <div data-aos="fade-up" data-aos-duration="1000">
            <p className='title_logistique underline'>Prestations</p>          
                <article className='container_prestations'>
                    <p className='main-title_prestations'>{language === "Fr" ? "Istanbul Beauty Care vous accompagne dans vos actes médicaux d'esthétique, dentaires et de soins" : "Istanbul Beauty Care will accompagny you with your medical appointments of esthetic, dentistry and treatments"}</p>
                    <div className='list-prestations'>
                        
                        <h2 className='titleUl underline2'>{language === "Fr" ? "Soins" : "Cares"}</h2>    
                          <DropdownText datas={[{ id: 1, title: 'Nos Prestations de Soins', titleEn: 'Our Care Services',elems: locationData.fifthList, versionEng: locationData.fifthListEng, isDifferent: true },]}/>
                          
                          <h3 className='titleUl'>{language === "Fr" ? "Dentaires"  : "Dental"}</h3>
                          <DropdownText datas={[{ id: 1, title: 'Nos Prestations Dentaires', titleEn: 'Our Dentistry', elems: locationData.sixList, versionEng: locationData.sixthListEng },]}/>
                          <h4 className='titleUl underline3'>{language === "Fr" ? "Médecine et Chirurgie Esthétique" : "Esthetic Medicine and Surgery"}</h4>
                          <DropdownText datas={[{ id: 1, title: 'PEV - Prestations Esthétiques du Visage', titleEn: 'ESF - Esthetic Services of the Face', elems: locationData.firstList, versionEng: locationData.firstListEng },]}/>
                          <DropdownText datas={[{ id: 1, title: 'PEM - Prestations Esthétiques des Membres', titleEn: 'ESM - Esthetic Services of the Members', elems: locationData.secondList, versionEng: locationData.secondListEng },]}/>
                          <DropdownText datas={[{ id: 1, title: 'PEC - Prestations Esthétiques du Corps', titleEn: 'ESB - Esthetic Services of the Body', elems: locationData.thirdList, versionEng: locationData.thirdListEng },]}/>
                          <DropdownText datas={[{ id: 1, title: 'PEI - Prestations Esthétiques Intimes', titleEn: 'ESI - Esthetic Services of the Intimate',elems: locationData.fourthList, versionEng: locationData.fourthListEng },]}/>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="1000">
                        <article className='announcement'><p className='contact_us_medic'>{language === "Fr" ? "Pour toute information complémentaire, " : "For any additional information, "}<br /></p><a href='/contact' className='btn-contact'>{language === "FR" ? "Contactez-nous" : "Contact-us"}</a></article>
                    </div>
                </article>
                
                </div>
                
                <div id='down'></div>
            </section>
            </div>
        </>
    )
}

export default Medic