import React from 'react'
import "./home.css"
import { useLanguage } from '../Context/LanguageContext';

function Videos({ videos, width, height }) {
    const { language } = useLanguage();
    return (
        <>
            {videos.map((video) => {
                console.log(video, language, video.content, video.frenchContent);

                return (
                    <article className='container_videos' key={video.index}>
                        <div className='card-overlay-home'>
                            <div className='container-icon'><video.icon className="icon-home-videos"/></div><p className={video.classContent} >{language === "EN" ? video.content : video.frenchContent}</p><video.icon className="icon-home-videos"/>
                        </div>
                        
                            {height && width ? (<div className="container_videos"><video className={video.classVid} alt={video.alt} controls height={height} width={width} type="video/mp4" loop controlsList="nodownload" poster={video.poster}>
                                <source src={video.video} type="video/mp4" />
                            </video></div>): (<div className="container_videos"><video className={video.classVid} alt={video.alt} controls height={"600px"} width={"1000px"} type="video/mp4" loop controlsList="nodownload" poster={video.poster}>
                                <source src={video.video} type="video/mp4" />
                            </video></div>)}
                            
                        
                    </article>
               )
                
            })}
        </>
    )
}

export default Videos